import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import propertiesReducer from '../reducers/properties';
import filtersReducer from '../reducers/propertiesFilters';
import authReducer from '../reducers/auth';
// import usersReducer from '../reducers/users';

// Use the below line to use Redux Dev tools
// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// Although "composeEnhancers" is needed instead when using redux-thunk  
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
        combineReducers({
            properties: propertiesReducer,
            filters: filtersReducer,
            auth: authReducer
            // users: usersReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
}

import * as firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyDtUpUnsYLEQBR-FCcmjr7YshT6Sx2AFsU",
  authDomain: "endnu-en-boligportal.firebaseapp.com",
  databaseURL: "https://endnu-en-boligportal.firebaseio.com",
  projectId: "endnu-en-boligportal",
  storageBucket: "endnu-en-boligportal.appspot.com",
  messagingSenderId: "821083709516",
  appId: "1:821083709516:web:829b4ac61d30fff9e554e9",
  measurementId: "G-PJXCSSN28C"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export { firebase, googleAuthProvider, facebookAuthProvider, database as default };
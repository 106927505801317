import React from 'react';
import Modal from 'react-modal';
import closeImage from "./images/x.png";
import moment from 'moment';

Modal.setAppElement('#root');

class EditProfileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileType: this.props.user.profileType,
      reasonForMoving: this.props.user.reasonForMoving,
      birthDay: moment(this.props.user.birthdate).format("D"),
      birthMonth: moment(this.props.user.birthdate).format("M"),
      birthYear: moment(this.props.user.birthdate).format("YYYY"),
      occupationType: this.props.user.occupationType,
      pets: this.props.user.pets,
      posted: this.props.user.posted,
      smoking: this.props.user.smoking,
      kids: this.props.user.kids,
      willingToShare: this.props.user.willingToShare,
      error: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'radio' ? target.value = (target.value === 'true') : target.value;
    const name = target.name;

    console.log(value)

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.birthDay || this.state.birthDay.substr(0, 1) === "0" || this.state.birthDay.length > 2) {
      this.setState(() => ({ error: 'Datoen er ikke skrevet korrekt' }));
    }
    else if (!this.state.birthMonth) {
      this.setState(() => ({ error: 'Vælg en måned' }));
    }
    else if (this.state.birthYear.length !== 4) {
      this.setState(() => ({ error: 'Skriv hele årstallet (f.eks. 1990)' }));
    }
    else {
      // Submit if passed all cases above
      const birthdate = moment(`
      ${e.target.birthDay.value}/
      ${e.target.birthMonth.value}/
      ${e.target.birthYear.value}`,
        "D/M/YYYY").valueOf();

      this.props.onSubmit({
        profileType: e.target.profileType.value,
        reasonForMoving: e.target.reasonForMoving.value,
        birthdate: parseInt(birthdate),
        occupationType: e.target.occupationType.value,
        pets: e.target.pets.value = (e.target.pets.value === 'true'),
        posted: e.target.posted.value = (e.target.posted.value === 'true'),
        smoking: e.target.smoking.value = (e.target.smoking.value === 'true'),
        kids: e.target.kids.value = (e.target.kids.value === 'true'),
        willingToShare: e.target.willingToShare.value = (e.target.willingToShare.value === 'true')
      });

      this.setState(() => ({ error: '' }));
      this.props.toggleModal();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modalVisible}
        onRequestClose={this.props.toggleModal}
        contentLabel="modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <img className="x" src={closeImage} alt="close button" onClick={this.props.toggleModal} />
        <form onSubmit={this.handleSubmit}>
          <label className="formLabel">
            Hvem er du/I?
          <select className="formInput" name="profileType" value={this.state.profileType} onChange={this.handleChange}>
              <option disabled value="">Vælg</option>
              <option value="man">Mand</option>
              <option value="woman">Kvinde</option>
              <option value="coupleOrFamily">Par/familie</option>
              <option value="friends">Venner</option>
              <option value="company">Virksomhed</option>
            </select>
          </label>
          <label className="formLabel">
            Hvorfor vil du flytte?
          <select className="formInput" name="reasonForMoving" value={this.state.reasonForMoving} onChange={this.handleChange}>
              <option disabled value="">Vælg</option>
              <option value="study">Nyt studie</option>
              <option value="job">Nyt job</option>
              <option value="movingInWithSomeone">Vil flytte sammen med en anden</option>
              <option value="other">Andet</option>
            </select>
          </label>
          <label className="formLabel">Fødselsdato</label>
          {this.state.error && <p className="labelError">{this.state.error}</p>}
          <div className="inputGroup">
            <input
              className="formInput" name="birthDay"
              type="text" maxLength={2} placeholder="Dag"
              value={this.state.birthDay}
              onChange={this.handleChange}
            />
            <select className="formInput" name="birthMonth" value={this.state.birthMonth} onChange={this.handleChange}>
              <option disabled value="">Måned</option>
              <option value="1">Januar</option>
              <option value="2">Februar</option>
              <option value="3">Marts</option>
              <option value="4">April</option>
              <option value="5">Maj</option>
              <option value="6">Juni</option>
              <option value="7">Juli</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">Oktober</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <input
              className="formInput" name="birthYear"
              type="text" maxLength={4} placeholder="År"
              value={this.state.birthYear} onChange={this.handleChange}
            />
          </div>
          <label className="formLabel">
            Beskæftigelse
          <select className="formInput" name="occupationType" value={this.state.occupationType} onChange={this.handleChange}>
              <option disabled value="">Vælg</option>
              <option value="student">Studerende</option>
              <option value="employed">I arbejde</option>
              <option value="unemployed">Ledig</option>
              <option value="retired">Pensioneret</option>
            </select>
          </label>
          <label className="formLabel">Har du kæledyr?</label>
          <div className="radios formRadios">
            <label><input type="radio" name="pets" value={true} checked={this.state.pets === true} onChange={this.handleChange} />Ja</label>
            <label><input type="radio" name="pets" value={false} checked={this.state.pets === false} onChange={this.handleChange} />Nej</label>
          </div>
          <label className="formLabel">Er du udstationeret?</label>
          <div className="radios formRadios">
            <label><input type="radio" name="posted" value={true} checked={this.state.posted === true} onChange={this.handleChange} />Ja</label>
            <label><input type="radio" name="posted" value={false} checked={this.state.posted === false} onChange={this.handleChange} />Nej</label>
          </div>
          <label className="formLabel">Ryger du?</label>
          <div className="radios formRadios">
            <label><input type="radio" name="smoking" value={true} checked={this.state.smoking === true} onChange={this.handleChange} />Ja</label>
            <label><input type="radio" name="smoking" value={false} checked={this.state.smoking === false} onChange={this.handleChange} />Nej</label>
          </div>
          <label className="formLabel">Har du børn?</label>
          <div className="radios formRadios">
            <label><input type="radio" name="kids" value={true} checked={this.state.kids === true} onChange={this.handleChange} />Ja</label>
            <label><input type="radio" name="kids" value={false} checked={this.state.kids === false} onChange={this.handleChange} />Nej</label>
          </div>
          <label className="formLabel">Er du villig til at dele en bolig?</label>
          <div className="radios formRadios">
            <label><input type="radio" name="willingToShare" value={true} checked={this.state.willingToShare === true} onChange={this.handleChange} />Ja</label>
            <label><input type="radio" name="willingToShare" value={false} checked={this.state.willingToShare === false} onChange={this.handleChange} />Nej</label>
          </div>
          {/* <label className="formLabel">
          Email
          <input className="formInput" type="email" />
          </label> */}
          {this.state.error && <p className="button generalError">Kontroller at formen er udfyldt korrekt og prøv igen</p>}
          <button className="button button-cta">Opdater</button>
        </form>
      </Modal>
    )
  }
};

export default EditProfileModal;
import React from 'react';
import { Link } from 'react-router-dom';
import './Forside.scss';

import { connect } from 'react-redux';
import { setPlaceId } from './actions/propertiesFilters';

// API info link
// https://dawa.aws.dk/dok/api/autocomplete

class Forside extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      municipalities: [],
      cities: [],
      places: [],
    }

    this.autocomplete = this.autocomplete.bind(this);
    this.autocompleteText = this.autocompleteText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  autocomplete(e) {
    if (e.target.value.length > 0) {
      fetch(`https://dawa.aws.dk/kommuner/autocomplete?q=${e.target.value}&per_side=3`)
      .then((item) => {
        item.json().then((item) => {
          this.setState({
            municipalities: item
          })
        });
      });

      fetch(`https://dawa.aws.dk/postnumre/autocomplete?q=${e.target.value}&per_side=5`)
      .then((item) => {
        item.json().then((item) => {
          this.setState({
            cities: item
          })
        });
      });

      // fetch(`https://dawa.aws.dk/stednavne2/autocomplete?q=${e.target.value}&hovedtype=Bebyggelse&undertype=bydel&navnestatus=officielt&per_side=5`)
      // .then((item) => {
      //   item.json().then((item) => {
      //     this.setState({
      //       places: item
      //     })
      //   });
      // });

      this.setState ({ 
        search: e.target.value,
        searching: true 
      })
    } else { 
      this.setState ({ 
        municipalities: [],
        cities: [],
        places: [],
        searching: false 
      })
    }
  }

  autocompleteText() {
    if (this.state.municipalities[0]) {
      return this.state.municipalities[0].kommune.navn.substr(this.state.search.length);
    }
    else if (this.state.cities[0]) {
      return this.state.cities[0].postnummer.navn.substr(this.state.search.length);
    }
  }

  onSubmit(e) {
    // e.preventDefault();

    const placeId = e.target.id;
    const placeName = e.target.getAttribute('name');

    this.props.dispatch(setPlaceId(placeId, placeName));
    this.props.history.push('/find');
  }

  render() {
    return (
      <div className="hero-background">
        <div className="hero-container">
          <h1>Hvor vil du bo?</h1>
          <div className="mainSearchContainer">
            <input type="text" placeholder="Skriv her..." onChange={this.autocomplete} />
            {this.state.searching &&
              <div>
                <div className="autocompleteText">
                  <span className="invisible">{this.state.search}</span>
                  {this.autocompleteText()}
                </div>
                <div className="autocomplete">

                  <p className="autocompleteTitle">Kommuner</p>
                  {this.state.municipalities.map(result => 
                      
                      <p 
                        className="result" 
                        key={result.kommune.kode} 
                        id={result.kommune.kode} 
                        name={result.kommune.navn} 
                        onClick={this.onSubmit}
                      >
                        {result.kommune.navn}
                      </p>
                  )}

                  <p className="autocompleteTitle">Byer</p>
                  {this.state.cities.map(result => 
                    <p 
                      className="result" 
                      key={result.postnummer.nr}
                      id={result.postnummer.nr}
                      name={result.postnummer.navn}
                      onClick={this.onSubmit}
                    >
                      {result.postnummer.nr} {result.postnummer.navn}
                    </p>
                  )}

                  {/* <p className="autocompleteTitle">Steder</p>
                  {
                    // this.state.places.map(result => console.log(result))
                    this.state.places.map(result => <Link to="#" onClick={console.log(result)} key={result.sted.id}>{result.navn} - {result.sted.kommuner[0].navn}</Link>)
                  } */}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

// export default Forside;

const mapStateToProps = (state) => {
  return {
    filters: state.filters
  }
}

export default connect(mapStateToProps)(Forside);


export const setMinRooms = (minRooms) => ({
    type: 'SET_MIN_ROOMS',
    minRooms
});
export const setMaxRooms = (maxRooms) => ({
    type: 'SET_MAX_ROOMS',
    maxRooms
});

export const setHousingType = (housingType) => ({
    type: 'SET_HOUSING_TYPE',
    housingType
});

export const setMinRent = (minRent) => ({
    type: 'SET_MIN_RENT',
    minRent
});

export const setMaxRent = (maxRent) => ({
    type: 'SET_MAX_RENT',
    maxRent
});

export const setMinSize = (minSize) => ({
    type: 'SET_MIN_SIZE',
    minSize
});

export const setMaxSize = (maxSize) => ({
    type: 'SET_MAX_SIZE',
    maxSize
});

export const setRentalPeriod = (rentalPeriod) => ({
    type: 'SET_RENTAL_PERIOD',
    rentalPeriod
});

export const setMoveInDate = (moveInDate) => ({
    type: 'SET_MOVE_IN_DATE',
    moveInDate
});

// Other
export const setPetsAllowed = (petsAllowed) => ({
    type: 'SET_PETS_ALLOWED',
    petsAllowed
});

export const setFurnished = (furnished) => ({
    type: 'SET_FURNISHED',
    furnished
});

export const setShareable = (shareable) => ({
    type: 'SET_SHAREABLE',
    shareable
});

export const setPlaceId = (placeId, placeName) => ({
    type: 'SET_PLACE_ID',
    placeId,
    placeName
});

export const removePlace = (placeId) => ({
    type: 'REMOVE_PLACE',
    placeId
});



// Sorting
export const sortByDate = () => ({
    type: 'SORT_BY_DATE'
});

export const sortByPrice = () => ({
    type: 'SORT_BY_PRICE'
});

export const sortBySize = () => ({
    type: 'SORT_BY_SIZE'
})

export const sortByRooms = () => ({
    type: 'SORT_BY_ROOMS'
})
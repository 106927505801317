import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

import {
  setPlaceId, removePlace,
  setHousingType,
  setMinRent, setMaxRent,
  setMinSize, setMaxSize,
  setMinRooms, setMaxRooms,
  setRentalPeriod,
  setPetsAllowed, setFurnished, setShareable,
  setMoveInDate
}
  from './actions/propertiesFilters';

// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory();

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      municipalities: [],
      cities: [],
      places: [],

      date: null,
      focused: false
    }

    // this.history = createBrowserHistory();

    // const URLParams = new URLSearchParams(history.location.search);
    // URLParams.forEach((value, key) => {
    //   switch (key) {
    //     case 'minRooms':
    //       this.props.dispatch(setMinRooms(value))
    //       break;
    //     default:
    //       break;
    //   }
    // })

    this.autocomplete = this.autocomplete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  autocomplete(e) {
    if (e.target.value.length > 0) {
      fetch(`https://dawa.aws.dk/kommuner/autocomplete?q=${e.target.value}&per_side=3`)
        .then((item) => {
          item.json().then((item) => {
            this.setState({
              municipalities: item
            })
          });
        });

      fetch(`https://dawa.aws.dk/postnumre/autocomplete?q=${e.target.value}&per_side=5`)
        .then((item) => {
          item.json().then((item) => {
            this.setState({
              cities: item
            })
          });
        });

      this.setState({
        search: e.target.value,
        searching: true
      })
    } else {
      this.setState({
        municipalities: [],
        cities: [],
        places: [],
        searching: false
      })
    }
  }

  onSubmit(e) {
    // e.preventDefault();
    const placeId = e.target.id;
    const placeName = e.target.getAttribute('name');
    this.props.dispatch(setPlaceId(placeId, placeName));
    this.setState({ searching: false });
    document.getElementById("locationInput").value = '';
  }

  render() {
    return (
      <form className="sidebar">
        <div className="option relative">
          <p className="optionTitle">Område</p>
          <input className="emptyField" type="text" id="locationInput" onChange={this.autocomplete} />
          <div className="locations">
            {this.props.filters.places.map(item =>
              <div key={item.placeId}>
                <span className="closeIcon" onClick={() => this.props.dispatch(removePlace(item.placeId))}>×</span>
                <span>{item.placeName}</span>
              </div>
            )}
          </div>
          <div className={`autocompleteSmall ${this.state.searching ? "" : "hide"}`}>
            <p className="autocompleteTitleSmall">Kommuner</p>
            {this.state.municipalities.map(result =>
              <p
                className="resultSmall"
                key={result.kommune.kode}
                id={result.kommune.kode}
                name={result.kommune.navn}
                onClick={this.onSubmit}
              >
                {result.kommune.navn}
              </p>
            )}

            <p className="autocompleteTitleSmall">Byer</p>
            {this.state.cities.map(result =>
              <p
                className="resultSmall"
                key={result.postnummer.nr}
                id={result.postnummer.nr}
                name={result.postnummer.navn}
                onClick={this.onSubmit}
              >
                {result.postnummer.nr} {result.postnummer.navn}
              </p>
            )}
          </div>
        </div>

        <div className="option">
          <p className="optionTitle">Boligtype</p>
          <div className="radios" onChange={(e) => {
            this.props.dispatch(setHousingType(e.target.value))
          }}>
            <label><input type="checkbox" name="housingType" value="1" />Villa</label>
            <label><input type="checkbox" name="housingType" value="2" />Rækkehus</label>
            <label><input type="checkbox" name="housingType" value="3" />Lejlighed</label>
            <label><input type="checkbox" name="housingType" value="4" />Værelse</label>
          </div>
        </div>
        <div className="option">
          <p className="optionTitle">Husleje <span>kr.</span></p>
          <div className="inputAndIcon">
            <input type="number" name="minRent" placeholder="Min" onChange={(e) =>
              this.props.dispatch(setMinRent(e.target.value))
            } />
            <span> - </span>
            <input type="number" name="maxRent" placeholder="Max" onChange={(e) =>
              this.props.dispatch(setMaxRent(e.target.value))
            } />
          </div>
        </div>
        <div className="option">
          <p className="optionTitle">Størrelse <span>m<sup>2</sup></span></p>
          <div className="inputAndIcon">
            <input type="number" name="minSize" placeholder="Min" onChange={(e) =>
              this.props.dispatch(setMinSize(e.target.value))
            } />
            <span> - </span>
            <input type="number" name="maxSize" placeholder="Max" onChange={(e) =>
              this.props.dispatch(setMaxSize(e.target.value))
            } />
          </div>
        </div>
        <div className="option">
          <p className="optionTitle">Værelser</p>
          <div className="inputAndIcon">
            <input type="number" name="minRooms" placeholder="Min" onChange={(e) => {
              // this.history.replace({
              //   pathname: '/find',
              //   search: '?minRooms=' + e.target.value
              // });
              this.props.dispatch(setMinRooms(e.target.value))
              }
            } />
            <span> - </span>
            <input type="number" name="maxRooms" placeholder="Max" onChange={(e) =>
              this.props.dispatch(setMaxRooms(e.target.value))
            } />
          </div>
        </div>
        <div className="option">
          <p className="optionTitle">Lejeperiode</p>
          <div className="radios" onChange={(e) => {
            this.props.dispatch(setRentalPeriod(e.target.value))
          }}>
            <label><input type="checkbox" name="length" value="1" />2-12 måneder</label>
            <label><input type="checkbox" name="length" value="2" />1-2 år</label>
            <label><input type="checkbox" name="length" value="3" />2+ år</label>
            <label><input type="checkbox" name="length" value="-1" />Ubegrænset</label>
          </div>
        </div>
        <div className="option">
          <p className="optionTitle">Overtagelsesdato</p>
          <SingleDatePicker
            date={this.state.date}
            onDateChange={date => {
              this.setState({ date });
              if (date) {
                this.props.dispatch(setMoveInDate(date.valueOf()));
              } else {
                this.props.dispatch(setMoveInDate(undefined));
              }

            }}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            showClearDate={true}
            id="your_unique_id"
            placeholder="Vælg dato"
          />
        </div>
        <div className="option">
          <p className="optionTitle">Andet</p>
          <div className="radios">
            <label>
              <input type="checkbox" name="type" value={1}
                onChange={(e) => { this.props.dispatch(setPetsAllowed(e.target.checked)) }} />
              Husdyr tilladt
            </label>
            <label>
              <input type="checkbox" name="type" value={1}
                onChange={(e) => { this.props.dispatch(setFurnished(e.target.checked)) }} />
              Møbleret
            </label>
            <label>
              <input type="checkbox" name="type" value={1}
                onChange={(e) => { this.props.dispatch(setShareable(e.target.checked)) }} />
              Delevenlig
            </label>
          </div>
        </div>
      </form>
    );
  }
}



const mapStateToProps = (state) => {
  // console.log(history.location)
  // var test = new URLSearchParams(history.location.search);
  // console.log(test);
  // test.forEach((value, key) => {
  //   console.log(value, key)
  // })
  // console.log(state.filters)



  // const queryToObject = Object.fromEntries(new URLSearchParams(history.location.search));  

  // const filter = {
  //   ...state.filters,
  //   ...queryToObject
  // }

  // console.log('Sidebar state: ', state.filters)
  // console.log('Sidebar.js custom: ', filter)

  // return {
  //   filters: filter
  // }
  return {
    filters: state.filters
  }
}

export default connect(mapStateToProps)(Sidebar);
import React from 'react';
import './Find.css';
import Sidebar from './Sidebar';
import CardList from './CardList';

import { connect } from 'react-redux';
import filterProperties from './selectors/properties';

// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory();

// import { startSetProperties } from './actions/properties';
// props.dispatch(startSetProperties()).then(() => { })

function Find(props) {
  return (
    <div className="searchContainer">
      <Sidebar />
      <CardList data={props.properties} />
    </div>
  );
};



// const mapStateToProps = (state) => {
//   const queryToObject = Object.fromEntries(new URLSearchParams(history.location.search));

//   const filter = {
//     ...state.filters,
//     ...queryToObject
//   }
//   console.log('Find state: ', state.filters)
//   console.log('Find custom: ', filter)
//   return {
//     properties: filterProperties(state.properties, filter)
//   }
// };
const mapStateToProps = (state) => ({
  properties: filterProperties(state.properties, state.filters)
});
export default connect(mapStateToProps)(Find);
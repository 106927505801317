import React from 'react';
import { connect } from 'react-redux';
import { startLogin, startLoginFacebook, startLoginEmail } from './actions/auth';

const Login = (props) => {
  return (
    <div className="container mtl">
      <div className="smallFormContainer">
        <h1>Log ind</h1>
        <button 
          className="button button-google mtsm" 
          onClick={() => props.dispatch(startLogin(props.startLogin))}>
          Log ind med Google
        </button>
        <button 
          className="button button-facebook mtsm" 
          onClick={() => props.dispatch(startLoginFacebook(props.startLoginFacebook))}>
          Log ind med Facebook
        </button>
        <hr className="mtl" />
        <form className="mtl" onSubmit={(e) => {
          e.preventDefault();
          props.dispatch(startLoginEmail(e.target.email.value, e.target.password.value));
        }} >
          <label className="formLabel">
            Email
            <input type="email" name="email" className="formInput" required />
          </label>
          <label className="formLabel">
            Adgangskode
            <input type="password" name="password" className="formInput" required />
          </label>
          <button className="button button-cta mts">Log ind</button>
        </form>
      </div>
    </div>
  )
}

// class Login extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       email: '',
//       password: ''
//     }

//     this.handleChange = this.handleChange.bind(this);
//   }

//   handleChange(event) {
//     const target = event.target;
//     const value = target.type === 'checkbox' ? target.checked : target.value;
//     const name = target.name;

//     this.setState({
//       [name]: value
//     });
//   }

//   render() {
//     return (
//       <div className="container mtl">
//         <div className="smallFormContainer">
//           <h1>Log ind</h1>
//           <button className="button button-google mtsm" onClick={this.props.startLogin}>Log ind med Google</button>
//           <button className="button button-facebook mtsm" onClick={this.props.startLoginFacebook}>Log ind med Facebook</button>
//           <hr className="mtl" />
//           <form className="mtl" onSubmit={(e) => {
//             e.preventDefault();
//             this.props.startLoginEmail();
//             // props.dispatch(startLoginEmail(e.target.email.value, e.target.password.value));
//           }} >
//             <label className="formLabel">
//               Email
//             <input type="email" name="email" className="formInput" value={this.props.email} onChange={this.handleChange} />
//             </label>
//             <label className="formLabel">
//               Adgangskode
//             <input type="password" name="password" className="formInput" value={this.props.password} onChange={this.handleChange} />
//             </label>
//             <button className="button button-cta mts">Log ind</button>
//           </form>
//         </div>
//         <button onClick={this.props.startLoginEmail}>startLoginEmail</button>
//       </div>
//     )
//   }
// }

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   startLogin: () => dispatch(startLogin()),
//   startLoginFacebook: () => dispatch(startLoginFacebook()),
//   startLoginEmail: () => dispatch(startLoginEmail(ownProps.email))
// });

// export default connect(undefined, mapDispatchToProps)(Login);
export default connect()(Login);
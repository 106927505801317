import React from 'react';
import { connect } from 'react-redux';
import PropertyForm from './PropertyForm';
import { startEditProperty, startRemoveProperty } from './actions/properties';

const EditProperty = (props) => { 
  console.log(props)
  return (
      <div>
        <h1>Rediger bolig!</h1>
        <p><b>Id:</b> {props.match.params.id}</p>
        <PropertyForm
          property={props.property}
          onSubmit={(property) => {
            // console.log(property);
            props.dispatch(startEditProperty(props.match.params.id, property));
            props.history.push('/find');
          }}
        />
        <hr/>
        <button onClick={() => {
          props.dispatch(startRemoveProperty({ id: props.match.params.id }))
          props.history.push('/find');
        }}>
          Slet bolig
        </button>
      </div>
    )
}

const mapStateToProps = (state, props) => {
  return {
    property: state.properties.find((property) => {
      return property.id === props.match.params.id;
    })
  }
}

export default connect(mapStateToProps)(EditProperty);
import React from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

moment.locale('da');

class PropertyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.property ? props.property.title : '',
      rooms: props.property ? props.property.rooms : '',
      date: props.property ? moment(props.property.date) : moment(),
      focused: false,
      error: ''
    }

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onRoomsChange = this.onRoomsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTitleChange(e) {
    const title = e.target.value;
    this.setState(() => ({ title }));
  }
  onRoomsChange(e) {
    const rooms = e.target.value;
    if (!rooms || rooms.match(/\d$/)) {
      this.setState(() => ({ rooms }));
    }
  }
  onSubmit(e) {
    e.preventDefault();

    if (!this.state.title) {
      this.setState(() => ({ error: 'Skriv en titel!' }));
    }
    else {
      this.setState(() => ({ error: '' }));
      this.props.onSubmit({
        title: this.state.title,
        rooms: parseInt(this.state.rooms),
        date: this.state.date.valueOf()
      })
    }
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        {this.state.error && <p>{this.state.error}</p>}
        <label>Titel
          <input type="text" value={this.state.title} onChange={this.onTitleChange} autoFocus/>
        </label>
        <label>Type af hus
          <input type="number"/>
        </label>
        <label>Antal værelser
          <input type="text" value={this.state.rooms} onChange={this.onRoomsChange} />
        </label>
        <label>Husleje
          <input type="number"/>
        </label>
        <label>Størrelse
          <input type="number"/>
        </label>
        <label>Overtagelse fra
          <SingleDatePicker
            date={this.state.date} // momentPropTypes.momentObj or null
            onDateChange={date => date && this.setState({ date })} // PropTypes.func.isRequired
            focused={this.state.focused} // PropTypes.bool
            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
            numberOfMonths={1}
            id="your_unique_id" // PropTypes.string.isRequired,
          />
        </label>
        <button>Opret bolig</button>
      </form>
    )
  }
}

export default PropertyForm;
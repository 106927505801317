import React from 'react';
import { connect } from 'react-redux';
import database from './firebase/firebase';

const Messages = (props) => {

  // fetch('https://dawa.aws.dk/postnumre?struktur=mini')
  // .then(response => response.json())
  // .then((values) => {
  //   values.forEach(value => {
  //     database.ref('cities/' + value.nr + '/city').set(value.navn);
  //   })
  // })

  // database.ref('cities/' + 7120 + '/city').set('Vejle Øst');

  return (
    <div>
      <a href="https://firebase.google.com/docs/cloud-messaging/js/client">Guide</a>
    </div>
  )
}

export default connect()(Messages);
export const filtersReducerDefault = {
    minRooms: 0,
    maxRooms: undefined,
    housingType: [],
    minRent: 0,
    maxRent: undefined,
    minSize: 0,
    maxSize: undefined,
    rentalPeriod: [],
    petsAllowed: undefined,
    furnished: undefined,
    shareable: undefined,
    moveInDate: undefined,
    places: [],
    sortBy: 'date'
}

const filtersReducer = (state = filtersReducerDefault, action) => {
    
    switch (action.type) {
        case 'SET_PLACE_ID':
            return {
                ...state,
                places: [...state.places, {
                    placeId: action.placeId,
                    placeName: action.placeName
                }] 
            }
        case 'REMOVE_PLACE':
            return {
                ...state,
                places: state.places.filter(item => item.placeId !== action.placeId)
            }
        // case 'SET_PLACE_ID':
        //     const placeId = action.placeId;
        //     const placeName = action.placeName;
        //     let placeIdsArray = [...state.placeIds];
        //     let placeNamesArray = [...state.placeNames];
            
        //     if (placeIdsArray.indexOf(placeId) === -1) {
        //         // Add to array
        //         placeIdsArray.push(placeId);
        //         placeNamesArray.push(placeName);
        //     } else {
        //         // Else remove
        //         placeIdsArray = placeIdsArray.filter(item => item !== placeId);
        //     }
        //     return {
        //         ...state,
        //         placeIds: placeIdsArray
        //     }
        case 'SORT_BY_DATE':
            return {
                ...state,
                sortBy: 'date'
            }
        case 'SORT_BY_PRICE':
            return {
                ...state,
                sortBy: 'price'
            }
        case 'SORT_BY_SIZE':
            return {
                ...state,
                sortBy: 'size'
            }
        case 'SORT_BY_ROOMS':
            return {
                ...state,
                sortBy: 'rooms'
            }
        case 'SET_MIN_ROOMS':
            return {
                ...state,
                minRooms: action.minRooms
            }
        case 'SET_MAX_ROOMS':
            return {
                ...state,
                maxRooms: action.maxRooms
            }
        case 'SET_HOUSING_TYPE':
            const housingType = parseInt(action.housingType);
            let housingTypeArray = [...state.housingType];
            
            if (housingTypeArray.indexOf(housingType) === -1) {
                // Add to array
                housingTypeArray.push(housingType);
            } else {
                // Else remove
                housingTypeArray = housingTypeArray.filter(item => item !== housingType);
            }

            return {
                ...state,
                housingType: housingTypeArray
            }
        case 'SET_RENTAL_PERIOD':
            const rentalPeriod = parseInt(action.rentalPeriod);
            let rentalPeriodArray = [...state.rentalPeriod];
            
            if (rentalPeriodArray.indexOf(rentalPeriod) === -1) {
                // Add to array
                rentalPeriodArray.push(rentalPeriod);
            } else {
                // Else remove
                rentalPeriodArray = rentalPeriodArray.filter(item => item !== rentalPeriod);
            }

            return {
                ...state,
                rentalPeriod: rentalPeriodArray
            }
        case 'SET_OTHER':
            const other = parseInt(action.other);
            let otherArray = [...state.other];
            
            if (otherArray.indexOf(other) === -1) {
                // Add to array
                otherArray.push(other);
            } else {
                // Else remove
                otherArray = otherArray.filter(item => item !== other);
            }

            return {
                ...state,
                other: otherArray
            }
        case 'SET_MIN_RENT':
            return {
                ...state,
                minRent: action.minRent
            }
        case 'SET_MAX_RENT':
            return {
                ...state,
                maxRent: action.maxRent
            }
        case 'SET_MIN_SIZE':
            return {
                ...state,
                minSize: action.minSize
            }
        case 'SET_MAX_SIZE':
            return {
                ...state,
                maxSize: action.maxSize
            }
        case 'SET_PETS_ALLOWED':
            return {
                ...state,
                petsAllowed: action.petsAllowed
            }
        case 'SET_FURNISHED':
            return {
                ...state,
                furnished: action.furnished
            }
        case 'SET_SHAREABLE':
            return {
                ...state,
                shareable: action.shareable
            }
        case 'SET_MOVE_IN_DATE':
            return {
                ...state,
                moveInDate: action.moveInDate
            }
        default:
            return state;
    }
}

export default filtersReducer;
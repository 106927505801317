import React from 'react';
import Card from './Card';

import { connect } from 'react-redux';
import { sortByDate, sortByPrice, sortBySize, sortByRooms } from './actions/propertiesFilters';

function CardList(props) {
    const list = (data) => {
        if (data.length > 0) {
            return data.map((item, index) => {
                return <Card key={index} item={item} />
            })
        }
        else {
            return 'Der kunne ikke findes nogle resultater';
        } 
    }

    return (
        <div>
            <div className="sortBar">
                <span>Sorter efter: </span>
                <select
                    // value={props.filters.sortBy}
                    onChange={(e) => {
                        if (e.target.value === 'price') {
                            props.dispatch(sortByPrice());
                        }
                        else if (e.target.value === 'size') {
                            props.dispatch(sortBySize());
                        }
                        else if (e.target.value === 'rooms') {
                            props.dispatch(sortByRooms());
                        }
                        else if (e.target.value === 'date') {
                            props.dispatch(sortByDate());
                        };
                    }}
                >
                    <option value="date">Nyeste</option>
                    <option value="price">Billigste</option>
                    <option value="size">Største</option>
                    <option value="rooms">Flest værelser</option>
                </select>
            </div>
            <div className="main">
                {list(props.data)}
            </div>
        </div>
    );
}

export default connect()(CardList);
import React from 'react';
import { connect } from 'react-redux';
import { firebase } from './firebase/firebase';
import { Link } from 'react-router-dom';
import EditProfileModal from './EditProfileModal';
import { startEditUser } from './actions/auth';
import './dashboard.css';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    }

    console.log('Dashboard.js this.props: ', this.props);

    this.user = firebase.auth().currentUser;
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => {
      return {
        modalVisible: !prevState.modalVisible
      }
    })
  }

  render() {
    return (
      <div>
        <div className="userHeader">
          <div className="container">
            <div className="userHeaderInner">
              <img
                src="https://www.xovi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
                alt=""
              />
              <h1>{this.user.displayName}</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <EditProfileModal
            modalVisible={this.state.modalVisible}
            toggleModal={this.toggleModal}
            user={this.props.user}
            onSubmit={(userData) => {
              // console.log(userData)
              const uid = this.props.user.uid;
              this.props.dispatch(startEditUser(uid, userData));
            }}
          />
          <div className="userOptions">
            <div className="userOption">
              <h4 className="userOptionTitle">Oplysninger</h4>
              <div className="userOptionContent">
                <p>Få flere og bedre boligtilbud fra udlejerne ved at gøre din profil mere troværdig.</p>
                <button className="button button-cta mtsm" onClick={this.toggleModal}>Udfyld din profil</button>
              </div>
            </div>
            <div className="userOption">
              <h4 className="userOptionTitle">Beskeder</h4>
              <div className="userOptionContent">
                <p>Se beskeder fra udlejerne du har skrevet til.</p>
                <Link to="#" className="button mtsm">Åben beskeder</Link>
              </div>
            </div>
            <div className="userOption">
              <h4 className="userOptionTitle">Gemte søgninger</h4>
              <div className="userOptionContent">
                <p>Få notifikationer hver gang der bliver sat nye boliger til leje indenfor dine specifikke ønsker.</p>
                <p className="mts">Gratis og nemt at afmelde.</p>
                <Link to="#" className="button mtsm">Bliv opdateret</Link>
              </div>
            </div>
            <div className="userOption">
              <h4 className="userOptionTitle">Gemte boliger</h4>
              <div className="userOptionContent">
                {
                  this.props.properties.map(property => (
                    <Link to={'/lejebolig/' + property.id} className="savedProperty">
                      <img src={property.images[0]} alt="" />
                      <div>
                        <b>{property.title}</b>
                        <p class="mtxs">{property.zip} {property.cityName}</p>
                      </div>
                    </Link>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
  properties: state.properties.filter(
    property => state.auth.savedProperties.indexOf(property.id) > -1
  )
});

export default connect(mapStateToProps)(Dashboard);
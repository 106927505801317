import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Nav from '../Nav';
import Forside from '../Forside';
import Find from '../Find';
import Login from '../Login';
import Property from '../Property';
import Dashboard from '../Dashboard';
import Messages from '../Messages';
import CreateProperty from '../CreateProperty';
import EditProperty from '../EditProperty';
import NotFound from '../NotFound';

export const history = createBrowserHistory();

class AppRouter extends React.Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <Nav />
          <Switch>
            <Route exact path="/" component={Forside}  />
            <Route path="/find" component={Find} />
            <Route path="/lejebolig/:id" component={Property} />
            <PublicRoute path="/log-ind" component={Login} />
            <PrivateRoute path="/beskeder" component={Messages} />
            <PrivateRoute path="/kontrolpanel" component={Dashboard} />
            <PrivateRoute path="/opret-bolig" component={CreateProperty} />
            <PrivateRoute path="/rediger-bolig/:id" component={EditProperty} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default AppRouter;

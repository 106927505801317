import database, { firebase, googleAuthProvider, facebookAuthProvider } from '../firebase/firebase';

export const login = (uid, userDetails) => ({
  type: 'LOGIN',
  uid,
  userDetails
});

export const startLogin = () => {
  return () => {
    return firebase.auth().signInWithPopup(googleAuthProvider);
  };
};

export const startLoginFacebook = () => {
  return () => {
    return firebase.auth().signInWithPopup(facebookAuthProvider);
  };
};

export const startLoginEmail = (email, password) => {
  console.log(email);
  return;
  return () => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };
};

// LOGOUT
export const logout = () => ({
  type: 'LOGOUT'
});

export const startLogout = () => {
  return () => {
    return firebase.auth().signOut();
  };
};


// TEST
export const startFetchLogin = (uid) => {
  return (dispatch) => {
    return database.ref(`users/${uid}`).once('value').then((snapshot) => {
      dispatch(login(uid, snapshot.val()));
    });
  };
};

export const editUser = (id, updates) => ({
  type: 'EDIT_USER',
  id,
  updates
});

export const startEditUser = (id, updates) => {
  return (dispatch) => {
      return database.ref('users/' + id).update(updates)
      .then(() => {
          dispatch(editUser(id, updates));
      });
  };
};

// Toogle if a property is saved
export const editSavedProperties = (id, updates) => ({
  type: 'EDIT_SAVED_PROPERTIES',
  id,
  updates
});

export const startEditSavedProperties = (uid, updates) => {
  return (dispatch) => {
      return database.ref('users/' + uid + '/savedProperties').set(updates)
      .then(() => {
          dispatch(editSavedProperties(uid, updates));
      });
  };
};


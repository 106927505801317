import React from 'react';
import { connect } from 'react-redux';
import PropertyForm from './PropertyForm';
import { startAddProperty } from './actions/properties';

const CreateProperty = (props) => {
  return (
    <div>
      <h1>Opret bolig!</h1>
      <PropertyForm onSubmit={(property) => {
        // console.log(property)
        props.dispatch(startAddProperty(property));
        props.history.push('/find');
      }} />
    </div>
  )
}

export default connect()(CreateProperty);
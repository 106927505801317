const propertiesReducer = (state = {}, action) => {
        
    switch (action.type) {
        case 'SET_PROPERTIES':
            // console.log('virker! i reducers/');
            // console.log(action.properties);
            return action.properties;
        case 'ADD_PROPERTY':
            return [
                ...state,
                action.property
            ];
        case 'EDIT_PROPERTY':
            return state.map(property => {
                if (property.id === action.id) {
                    console.log(action.updates)
                    return {
                        ...property,
                        ...action.updates
                    }                   
                } else {
                    return property;
                }
            });
        case 'REMOVE_PROPERTY':
            return state.filter(property => property.id !== action.id);
        default:
            return state;
    }
}

export default propertiesReducer;
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import 'normalize.css/normalize.css';
import AppRouter, { history } from './routers/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

import { startSetProperties } from './actions/properties';
import { firebase } from './firebase/firebase';

import { login, startFetchLogin, logout } from './actions/auth';

const store = configureStore();

// store.dispatch(startSetProperties());

// ReactDOM.render(<p>Loading...</p>, document.getElementById('root'));

// store.dispatch(startSetProperties()).then(() => {
//   ReactDOM.render(
//     <React.StrictMode>
//     <BrowserRouter>
//       <Provider store={store}>
//         <AppRouter />
//       </Provider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
//   )
// })

// firebase.auth().onAuthStateChanged((user) => {
//   console.log(user.uid)
//   if (user) {
//     store.dispatch(login(user.uid));
//     console.log('user is a truthy value');
//     if (history.location.pathname === '/log-ind') {
//       history.push('/kontrolpanel');
//     }
//   }
//   else {
//     store.dispatch(logout());
//     console.log('user is a falsy value');
//   }
// });

const jsx = (
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

let hasRendered = false;

const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    hasRendered = true;
  }
};

// ReactDOM.render(<p>Loading...</p>, document.getElementById('root'));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // store.dispatch(login(user.uid));
    store.dispatch(startFetchLogin(user.uid)).then(() => {
      store.dispatch(startSetProperties()).then(() => {
        renderApp();
        if (history.location.pathname === '/log-ind') {
          history.push('/kontrolpanel');
        };
      });
    });

  } else {
    store.dispatch(logout());
    store.dispatch(startSetProperties()).then(() => {
      renderApp();
    });
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { Link } from 'react-router-dom';

export default function Card(props) {
    return (
        <div className="card">
            <Link to={'/lejebolig/' + props.item.id} className="cardInner">
                <img
                    src={props.item.images[0]}
                    alt=""
                />
                <div className="desc">
                    <p className="cardTitle">{props.item.autoTitle}</p>
                    <p>{`${props.item.cityName}, ${props.item.streetName}`}</p>
                    <p>{props.item.rent} kr.</p>
                    {/* <p><Link to={'rediger-bolig/' + props.item.id}>Rediger bolig</Link></p> */}
                </div>
            </Link>
        </div>
    );
}
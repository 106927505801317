const filterProperties = (properties, 
    {minRooms, maxRooms, 
    housingType, 
    minRent, maxRent, 
    minSize, maxSize, 
    rentalPeriod, 
    petsAllowed, 
    furnished, 
    shareable,
    moveInDate,
    places,
    sortBy}) => {
    return properties.filter(property => {
        const minRoomsMatch = property.rooms >= minRooms;
        const maxRoomsMatch = !maxRooms || property.rooms <= maxRooms;
        const housingTypeMatch = housingType.length === 0 || housingType.indexOf(property.housingType) > -1;
        const minRentMatch = property.rent >= minRent;
        const maxRentMatch = !maxRent || property.rent <= maxRent;
        const minSizeMatch = property.size >= minSize;
        const maxSizeMatch = !maxSize || property.size <= maxSize;
        const rentalPeriodMatch = rentalPeriod.length === 0 || rentalPeriod.indexOf(property.rentalPeriod) > -1;
        const petsAllowedMatch = !petsAllowed || property.petsAllowed;
        const furnishedMatch = !furnished || property.furnished;
        const shareableMatch = !shareable || property.shareable;
        const moveInDateMatch = !moveInDate || property.moveInNow || property.moveInDate <= moveInDate;
        const placesMatch = places.length === 0 
        || places.filter(item => item.placeId === property.zip 
            || item.placeId === property.municipalityId).length >= 1; 

        return minRoomsMatch && maxRoomsMatch && 
        housingTypeMatch && 
        minRentMatch && maxRentMatch && 
        minSizeMatch && maxSizeMatch && 
        rentalPeriodMatch && 
        petsAllowedMatch &&
        furnishedMatch &&
        shareableMatch &&
        moveInDateMatch &&
        placesMatch;
    }).sort((a, b) => {
        switch (sortBy) {
            case 'date':
                return a.createdAt > b.createdAt ? 1 : -1;
            case 'price':
                return a.rent > b.rent ? 1 : -1;
            case 'rooms':
                return a.rooms < b.rooms ? 1 : -1;
            case 'size':
                return a.size < b.size ? 1 : -1;
            default:
                return a.createdAt > b.createdAt ? 1 : -1;
        }
    });
};

export default filterProperties;
import React from 'react';
import { connect } from 'react-redux';
import { startEditSavedProperties } from './actions/auth';
import moment from 'moment';
import database from './firebase/firebase';
import PropertyModal from './PropertyModal.js';
import './property.css';

import Flickity from 'react-flickity-component'
import './flickity.css';
import './fullscreen.js';
import './fullscreen.css';

import heartHollow from "./images/heartHollow.png";
import heartFilled from "./images/heartFilled.png";
import heartFilledWhite from "./images/heartFilledWhite.png";

import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';

class Property extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      savedProperty: false,
      modalVisible: false,
      moveInPrice:
        this.props.property.rent +
        this.props.property.prepaidRent +
        this.props.property.account +
        this.props.property.deposit
    }

    this.rentalPeriod = new Map();
    this.rentalPeriod.set(-1, 'Ubegrænset');
    this.rentalPeriod.set(1, '2-12 måneder');
    this.rentalPeriod.set(2, '1-2 år');
    this.rentalPeriod.set(3, '2+ år');

    switch (this.props.property.housingType) {
      case 1:
        this.housingType = 'Villa';
        break;
      case 2:
        this.housingType = 'Rækkehus';
        break;
      case 3:
        this.housingType = 'Lejlighed';
        break;
      case 4:
        this.housingType = 'Værelse';
        break;
      default:
        break;
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleSavedProperty = this.toggleSavedProperty.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => {
      return {
        modalVisible: !prevState.modalVisible
      }
    })
  }

  toggleSavedProperty() {
    if (this.state.savedProperty) {
      // If property is currently saved, then remove it
      let updates = this.props.user.savedProperties.filter(property => property !== this.props.property.id);
      this.props.dispatch(startEditSavedProperties(this.props.user.uid, updates));
    }
    else {
      // Else add it
      let updates = this.props.user.savedProperties ? this.props.user.savedProperties.concat(this.props.property.id) : [this.props.property.id];
      this.props.dispatch(startEditSavedProperties(this.props.user.uid, updates));
    }

    this.setState((prevState) => {
      return {
        savedProperty: !prevState.savedProperty
      }
    });
  }

  componentDidMount = () => {
    this.flkty.on('staticClick', () => {
      this.flkty.viewFullscreen()
    });

    // this.props.user.savedProperties.indexOf(this.props.property.id) > -1

    // Check if property is saved by the user
    const savedProperties = this.props.user.savedProperties;
    if (savedProperties && !Array.isArray(savedProperties)) {
      // truthy, but not array
      if (savedProperties[Object.keys(savedProperties)[0]] === this.props.property.id) {
        this.setState({ savedProperty: true })
      }
    }
    else if (Array.isArray(savedProperties)) {
      // Is an array
      if (savedProperties.indexOf(this.props.property.id) > -1) {
        this.setState({ savedProperty: true })
      }
    }
  }

  render() {
    return (
      <div className="property" id="property">
        <PropertyModal modalVisible={this.state.modalVisible} toggleModal={this.toggleModal} />
        <div className="property__slider">
          <Flickity
            flickityRef={c => this.flkty = c}
            className={'carousel'}
            elementType={'div'}
            options={{
              wrapAround: true,
              initialIndex: 1,
              pageDots: false,
              fullscreen: true
            }}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
          >
            {this.props.property.images.map(item =>
              <div className="carousel-cell" style={{ backgroundImage: `url('${item}')` }}></div>
            )}
          </Flickity>
        </div>
        <div className="property__heading">
          <div className="container">
            <div>
              <h1>{this.props.property.autoTitle}</h1>
              <h2>
                {this.props.property.streetName},&nbsp;
                {this.props.property.zip}&nbsp;
                {this.props.property.cityName}
              </h2>
            </div>
          </div>
        </div>
        <div className="property__body">
          <div className="container">
            <div className="property__sidebar">
              <div className="property__sidebar_inner">
                <div className="property__sidebar_top">
                  <span>Indtrykket - - -</span>
                  <img 
                    src={this.state.savedProperty ? heartFilledWhite : heartHollow } 
                    alt=""
                    onClick={this.toggleSavedProperty}
                  />
                </div>
                <div className="property__sidebar_box">
                  <div className="mts">
                    <span className="property__sidebar_price">{this.props.property.rent.toLocaleString('da-DK')}</span>
                    <span className="property__sidebar_priceunit">kr/md.</span>
                  </div>
                  <div className="mtsm">
                    <div className="property__sidebar_details_line">
                      <span>Månedlig aconto</span>
                      <span>{this.props.property.account.toLocaleString('da-DK')} kr.</span>
                    </div>
                    <div className="property__sidebar_details_line">
                      <span>Indflytningspris</span>
                      <span>{this.state.moveInPrice.toLocaleString('da-DK')} kr.</span>
                    </div>
                  </div>
                  <div className="mtsm">
                    <div className="property__sidebar_details_line">
                      <span>Overtagelse {this.props.property.moveInNow ? 'snarest'
                        : moment(this.props.property.moveInDate).format('DD/MM-YYYY')}</span>
                    </div>
                    <div className="property__sidebar_details_line">
                      <span>{this.rentalPeriod.get(this.props.property.housingType)} lejeperiode</span>
                    </div>
                  </div>
                  <div className="mtm">
                    <button className="button button-cta">Skriv til udlejer</button>
                    {this.props.property.phoneContact &&
                      <button className="button mts">
                        Ring på {this.props.property.phoneContact.substr(0, 2)}xx xxxx
                      </button>
                    }
                  </div>
                </div>
                <div className="property__sidebar_report">
                  <p onClick={this.toggleModal}>Rapporter annonce</p>
                </div>
              </div>
            </div>
            <div className="property__content">
              <div className="property__content_desc">
                <h3 className="property__content_title">{this.props.property.title}</h3>
                <div className="">
                  {this.props.property.description}
                </div>
              </div>
              <div className="property__content_details">
                <h5 className="property__content_details_title mtm">Detaljer om boligen</h5>
                <div className="AdFeatures">
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Boligtype</span>
                    <span className="AdFeatures__item-value">{this.housingType}</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Størrelse</span>
                    <span className="AdFeatures__item-value">{this.props.property.size} m²</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Værelser</span>
                    <span className="AdFeatures__item-value">{this.props.property.rooms}</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Etage</span>
                    <span className="AdFeatures__item-value">{this.props.property.floor}</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Møbleret</span>
                    <span className="AdFeatures__item-value">{this.props.property.furnished ? 'Ja' : 'Nej'}</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Delevenlig</span>
                    <span className="AdFeatures__item-value">{this.props.property.shareable ? 'Ja' : 'Nej'}</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Husdyr tilladt</span>
                    <span className="AdFeatures__item-value">{this.props.property.petsAllowed ? 'Ja' : 'Nej'}</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Energimærke</span>
                    <span className="AdFeatures__item-value">{this.props.property.energyLabel ? this.props.property.energyLabel : '-'}</span>
                  </div>
                </div>
                <h5 className="property__content_details_title mtm">Detaljer om udlejningen</h5>
                <div className="AdFeatures">
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Lejeperiode</span>
                    <span className="AdFeatures__item-value">{this.rentalPeriod}</span>
                  </div><div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Overtagelsesdato</span>
                    <span className="AdFeatures__item-value">
                      {this.props.property.moveInNow ? 'Snarest' :
                        moment(this.props.property.moveInDate).format('DD/MM-YYYY')}
                    </span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Månedlig leje</span>
                    <span className="AdFeatures__item-value">{this.props.property.rent.toLocaleString('da-DK')} kr.</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Aconto</span>
                    <span className="AdFeatures__item-value">{this.props.property.account.toLocaleString('da-DK')} kr.</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Depositum</span>
                    <span className="AdFeatures__item-value">{this.props.property.deposit.toLocaleString('da-DK')} kr.</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Forudbetalt leje</span>
                    <span className="AdFeatures__item-value">{this.props.property.prepaidRent.toLocaleString('da-DK')} kr.</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Indflytningspris</span>
                    <span className="AdFeatures__item-value">{this.state.moveInPrice.toLocaleString('da-DK')} kr.</span>
                    {/* <div className="AdFeatures__item-additional">
                      <a href="#" className="AdFeatures__item-additional-link" target="_blank">Har du brug for et lån?</a>
                    </div> */}
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">Oprettelsesdato</span>
                    <span className="AdFeatures__item-value">{moment(this.props.property.createdAt).format('DD/MM-YYYY')}</span>
                  </div>
                  <div className="AdFeatures__item">
                    <span className="AdFeatures__item-label">ID</span>
                    <span className="AdFeatures__item-value">{this.props.property.propertyId}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mapContainer">
          <div className="leaflet-container">
            <LeafletMap
              center={[this.props.property.lat, this.props.property.lng]}
              zoom={14}
              maxZoom={16}
              minZoom={11}
              attributionControl={true}
              zoomControl={true}
              doubleClickZoom={true}
              scrollWheelZoom={false}
              dragging={true}
              animate={true}
              easeLinearity={0.35}
            >
              <TileLayer
                url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
              />
              <Marker position={[this.props.property.lat, this.props.property.lng]}></Marker>
            </LeafletMap>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    property: state.properties.find((property) =>
      property.id === props.match.params.id
    ),
    user: state.auth
  }
}

export default connect(mapStateToProps)(Property);
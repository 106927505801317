import database from '../firebase/firebase';

export const addProperty = (property) => ({
    type: 'ADD_PROPERTY',
    property
});

export const startAddProperty = (propertyDate = {}) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        const {
            title = 'Untitled',
            rooms = 0,
            housingType = 1,
            rent = 0,
            size = 0,
            createdAt = 0,
            date = undefined 
        } = propertyDate;
        const property = { title, rooms, housingType, rent, size, createdAt, date };

        return database.ref(`users/${uid}/properties`).push(property).then((ref) => {
        // return database.ref('properties').push(property).then((ref) => {
            dispatch(addProperty({
                id: ref.key,
                ...property
            }));
        });
    };
};

export const editProperty = (id, updates) => ({
    type: 'EDIT_PROPERTY',
    id,
    updates
});

export const startEditProperty = (id, updates) => {
    return (dispatch) => {
        return database.ref('properties/' + id).update(updates)
        .then(() => {
            dispatch(editProperty(id, updates));
        });
    };
};

export const removeProperty = (id = undefined) => ({
    type: 'REMOVE_PROPERTY',
    id
});

export const startRemoveProperty = ({id = undefined}) => {
    if (id) {
        return (dispatch) => {
            return database.ref('properties/' + id).remove().then(() => {
                dispatch(removeProperty(id));
            });
        };
    };
};

export const setProperties = (properties) => ({
    type: 'SET_PROPERTIES',
    properties
});

export const startSetProperties = () => {
    return (dispatch) => {
        return database.ref('properties').once('value').then((snapshot) => {
            const properties = [];

            snapshot.forEach((childSnapshot) => {
                properties.push({
                    id: childSnapshot.key,
                    ...childSnapshot.val()
                });
            });

            dispatch(setProperties(properties));
        });
    };
};
export default (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        uid: action.uid,
        ...action.userDetails
      };
    case 'LOGOUT':
      return {};
    case 'EDIT_USER':
      if (state.uid === action.id) {
        return {
          ...state,
          ...action.updates
        }
      } else {
        return state;
      }
    case 'EDIT_SAVED_PROPERTIES':
      if (state.uid === action.id) {
        return {
          ...state,
          savedProperties: action.updates
        }
      } else {
        return state;
      }
    default:
      return state;
  }
};
import React from 'react';
import './Nav.css';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { startLogout } from './actions/auth';

function Nav({ isAuthenticated }) {
    return (
        <nav>
            <div className="logo-div">
                <NavLink to="/">Endnu en boligportal</NavLink>
            </div>
            <div className="menu-div">
                <ul>
                    <li><NavLink to="/find" activeClassName="activeNav">Søg bolig</NavLink></li>
                    <li><NavLink to="/opret-bolig" activeClassName="activeNav">Udlej bolig</NavLink></li>
                    {!isAuthenticated
                        ? 
                        <React.Fragment>
                            <li><NavLink to="#" activeClassName="activeNav">Opret bruger</NavLink></li>
                            <li><NavLink to="/log-ind" activeClassName="activeNav">Log ind</NavLink></li>
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            <li><NavLink to="/beskeder" activeClassName="activeNav">Beskeder</NavLink></li>
                            <li><NavLink to="/kontrolpanel" activeClassName="activeNav">Min profil</NavLink></li>
                            <li><NavLink to="/" onClick={startLogout()}>Log ud</NavLink></li>
                        </React.Fragment>
                    }
                </ul>
            </div>
        </nav>
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid
})

const mapDispatchToProps = (dispatch) => ({
    startLogout: () => dispatch(startLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
import React from 'react';
import Modal from 'react-modal';
import closeImage from "./images/x.png";
Modal.setAppElement('#root');

const PropertyModal = (props) => (
  <Modal
    isOpen={props.modalVisible}
    onRequestClose={props.toggleModal}
    contentLabel="test"
    className="Modal"
    overlayClassName="Overlay"
  >
    <img className="x" src={closeImage} alt="close button" onClick={props.toggleModal} />
    <p>Modal indhold...</p>
  </Modal>
);

export default PropertyModal;